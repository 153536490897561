import { useState, useEffect, useRef } from 'react';
// @ts-ignore
import useStateRef from 'react-usestateref';
import * as LotService from '../../services/Lot';
import * as CategoryService from '../../services/Category';
import * as CertifierService from '../../services/Certifier';

import { Table, Modal, Input, Button, Space, Drawer, Spin, Form, DatePicker, Descriptions, Popconfirm, Select } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { Certifier, INTERACTIONS, Lot, LotCategory, LotDetail, SOLD_STATE } from '../../utils/general';

import { Layout } from 'antd';
import LayoutGeneral from '../../components/Layout';
import moment from 'moment';
import _ from 'lodash';
import 'moment/locale/es';

import './styles.scss';

const { Content } = Layout;
const { Option } = Select;

const { RangePicker } = DatePicker;

const Lots = () => {
  const [lots, setLots, lotsRef] = useStateRef<Lot[]>();
  const [lotsAux, setLotsAux, lotsAuxRef] = useStateRef<Lot[]>();
  
  const [allFiltered, setAllFiltered, allFilteredRef] = useStateRef<Lot[]>();
  const [filteredCategories, setFilteredCategories, filteredCategoriesRef] = useStateRef<Lot[]>();
  const [filteredCertifiers, setFilteredCertifiers, filteredCertifiersRef] = useStateRef<Lot[]>();

  const [categories, setCategories] = useState<LotCategory[]>();
  const [certifiers, setCertifiers] = useState<Certifier[]>();

  const [to, setTo] = useState<number>();
  const [from, setFrom] = useState<number>();

  const [searchedColumn, setSearchedColumn] = useState<any>();
  const [searchText, setSearchText] = useState<any>();
  const searchInput = useRef<any>();

  const [rowSelected, setRowSelected] = useState<any>();

  const [amountAnimals, setAmountAnimals] = useState<number>();
  const [amountLots, setAmountLots] = useState<number>();

  const [visible, setVisible] = useState<boolean>(false);
  const [dataLot, setDataLot] = useState<LotDetail>();

  const [filterValue, setFilterValue] = useState<any>();
  const [sorterValue, setSorterValue] = useState<any>();

  const [searching, setSearching] = useState<boolean>();
  const [restart, setRestart] = useState<boolean>(true);
  const [loadingData, setLoading] = useState<boolean>(true);

  const [currentHeightScreen, setCurrentHeightScreen] = useState<number>();

  const [isAsignCertifierModalVisible, setIsAsignCertifierModalVisible] = useState<boolean>(false);
  const [selectCertifier, setSelectCertifier] = useState<Certifier>();

  const showAsignCertifierModal = () => {
    if (dataLot?.certifier) {
      const cer = certifiers?.find((cer: Certifier) => `${cer?.name.first} ${cer?.name.last}` === dataLot?.certifier);
      setSelectCertifier(cer);
    }
    setIsAsignCertifierModalVisible(true);
  };

  const handleOk = async () => {
    // update data and call servide to reload view
    if (dataLot && selectCertifier && dataLot.certifier !== `${selectCertifier?.name.first} ${selectCertifier?.name.last}`) {
      await LotService.updateCertifier(dataLot?.id, selectCertifier?.id);
    }
    setDataLot(undefined);
    setRowSelected('');
    setIsAsignCertifierModalVisible(false);
    setVisible(false);
    setSearching(true);
  };

  const handleCancel = () => {
    setIsAsignCertifierModalVisible(false);
  };

  const onChange = (certifierId: string) => {
    const cer = certifiers?.find((cer: Certifier) => cer?.id === certifierId);
    setSelectCertifier(cer);
  }

  const clearFiltersValues = () => {
    setFilterValue(null);
    setSorterValue(null);
  };

  const handleResize = () => setCurrentHeightScreen((window.innerHeight - 250));
  window.addEventListener('resize', handleResize);

  const getData = async () => {
    setLoading(true);
    let res;
    if (to && from) {
      res = await LotService.getAll(from, to);
    } else {
      res = await LotService.getAll();
    }

    setRestart(false);

    const lotsData : Lot[] = res.data.map((lot: Lot) => {
      let soldRender;
      if (lot.sold === 'SOLD') {
        soldRender = <CheckCircleOutlined style={{
          color: 'green',
        }} />
      } else if (lot.sold === 'NOT_SOLD') {
        soldRender = <CloseCircleOutlined style={{
          color: 'red',
        }} />
      } else {
        soldRender = '';
      }

      return {
        ...lot,
        activeDays: Math.round(lot.activeDays),
        createdAtFormated: moment(lot.createdAt).format('ddd D MMMM YYYY'),
        inactiveSinceFormated: lot?.inactiveSince && moment(lot.inactiveSince).format('ddd D MMMM YYYY'),
        categoryName: lot.category.name,
        certifierName: lot?.certifier ? lot.certifier.name : '',
        soldRender,
      }
    });

    setLots(lotsData);
    setLotsAux(lotsData);
    setAmountLots(lotsData? lotsData.length : 0);
    setAmountAnimals(lotsData.length > 0 ? lotsData.map(lot => lot?.animalsCount).reduce((total: any, value: any) => total + value) : 0);
    setSearching(false);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [restart === true]);

  useEffect(() => {
    const getCategories = async () => {
      const response = await CategoryService.getAll();
      setCategories(response.data);
    };
    const getCertifiers = async () => {
      const response = await CertifierService.getAll();
      setCertifiers(response.data);
    }

    handleResize();
    getCategories();
    getCertifiers();
  }, []);

  useEffect(() => {
    setCategories(categories?.map((cat: LotCategory) => ({
      ...cat,
      total: lots?.filter((lot: Lot) => lot.category.id === cat._id).length,
    })));
    setCertifiers(certifiers?.map((certifier: Certifier) => ({
      ...certifier,
      total: lots?.filter((lot: Lot) => lot?.certifier?.id === certifier.id).length,
    })));
  }, [lots, searching === true]);

  useEffect(() => {
    setCertifiers(certifiers?.map((certifier: Certifier) => ({
      ...certifier,
      total: filterValue?.certifierName ?
        Object.values(filterValue).filter(x => x !== null).length == 1 ? 
        lots?.filter((lot: Lot) => lot?.certifier?.id === certifier.id).length :
        lotsAuxRef.current.length > allFilteredRef.length ?
          lotsAuxRef.current?.filter((lot: Lot) => lot?.certifier?.id === certifier.id).length
          : allFilteredRef.current?.filter((lot: Lot) => lot?.certifier?.id === certifier.id).length
        : 
        lotsAuxRef.current?.filter((lot: Lot) => lot?.certifier?.id === certifier.id).length,
    })));
  }, [allFiltered]);

  useEffect(() => {
    setCategories(categories?.map((cat: LotCategory) => ({
      ...cat,
      // total: filterValue?.categoryName ?
      // (
      //   lotsRef.current.length > lotsAuxRef.current.length ?
      //   lotsRef.current?.filter((lot: Lot) => lot.category.id === cat._id).length
      //   :
      //   lotsAuxRef.current?.filter((lot: Lot) => lot.category.id === cat._id).length
      // )
      // : allFilteredRef.current?.filter((lot: Lot) => lot.category.id === cat._id).length,
      total: filterValue?.categoryName ?
        Object.values(filterValue).filter(x => x !== null).length == 1 ? 
          lots?.filter((lot: Lot) => lot.category.id === cat._id).length : 
            lotsAuxRef.current.length > allFilteredRef.length ?
            lotsAuxRef.current?.filter((lot: Lot) => lot.category.id === cat._id).length
            :
            allFilteredRef.current?.filter((lot: Lot) => lot.category.id === cat._id).length
          :
          lotsAuxRef.current?.filter((lot: Lot) => lot.category.id === cat._id).length,
    })));
  }, [allFiltered]);

  const getColumnSearchProps = (dataIndex: any) : any => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: {
      setSelectedKeys: Function,
      selectedKeys: [string],
      confirm: Function,
      clearFilters: Function
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: string) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: string, record: any) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible: string) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text: string) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys: any, confirm: Function, dataIndex: number) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters: Function) => {
    clearFilters();
    clearFiltersValues();
    setLots(lotsAux);
    setSearchText('');
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'refNumber',
      width: 100,
      key: 'refNumber',
      ...getColumnSearchProps('refNumber'),
      sortOrder: sorterValue?.columnKey === 'refNumber' && sorterValue.order,
      sorter: (a: Lot, b: Lot) => Number(a.refNumber) - Number(b.refNumber),
    },
    {
      title: 'Categoria',
      dataIndex: 'categoryName',
      key: 'categoryName',
      filters: categories?.map((cat: LotCategory) => ({
        text: `${cat.name}`,
        value: cat.name,
      })),
      filteredValue: filterValue?.categoryName || null,
      onFilter: (value: string, record: Lot) => record.category.name === value,
    },
    {
      title: 'Cant. animales',
      dataIndex: 'animalsCount',
      width: 100,
      key: 'animalsCount',
      sortOrder: sorterValue?.columnKey === 'animalsCount' && sorterValue.order,
      sorter: (a: Lot, b: Lot) => Number(a?.animalsCount) - Number(b?.animalsCount),
    },
    {
      title: 'Publicado',
      dataIndex: 'createdAtFormated',
      key: 'createdAtFormated',
      sortOrder: sorterValue?.columnKey === 'createdAtFormated' && sorterValue.order,
      sorter: (a: Lot, b: Lot) => moment(a.createdAt).diff(moment(b.createdAt)),
    },
    {
      title: 'Inactivo desde',
      dataIndex: 'inactiveSinceFormated',
      key: 'inactiveSinceFormated',
      sortOrder: sorterValue?.columnKey === 'inactiveSinceFormated' && sorterValue.order,
      sorter: (a: Lot, b: Lot) => moment(a.inactiveSince).diff(moment(b.inactiveSince)),
    },
    {
      title: 'Estado',
      dataIndex: 'state',
      width: 100,
      key: 'state',
      filters: [
        {
          text: 'ACTIVE',
          value: 'ACTIVE',
        },
        {
          text: 'INACTIVE',
          value: 'INACTIVE',
        },
      ],
      filteredValue: filterValue?.state || null,
      onFilter: (value: string, record: Lot) => record.state === value,
    },
    {
      title: 'Días activado',
      dataIndex: 'activeDays',
      width: 100,
      key: 'activeDays',
      sortOrder: sorterValue?.columnKey === 'activeDays' && sorterValue.order,
      sorter: (a: Lot, b: Lot) => a.activeDays - b.activeDays,
    },
    {
      title: 'Vendido',
      dataIndex: 'soldRender',
      key: 'soldRender',
      width: 100,
      filters: Object.values(SOLD_STATE).map((value: string) => ({
        text: value,
        value,
      })),
      filteredValue: filterValue?.soldRender || null,
      onFilter: (value: string, record: Lot) => record.sold === value,
    },
    {
      title: 'Interac',
      dataIndex: 'interactions',
      width: 100,
      key: 'interactions',
      sortOrder: sorterValue?.columnKey === 'interactions' && sorterValue.order,
      sorter: (a: Lot, b: Lot) => a.interactions - b.interactions,
    },
    {
      title: 'Certifier',
      dataIndex: 'certifierName',
      key: 'certifierName',
      filters: certifiers?.map((cer: Certifier) => ({
        text: `${cer.name.first} ${cer.name.last}`,
        value: cer.id,
      })),
      filteredValue: filterValue?.certifierName || null,
      onFilter: (value: string, record: Lot) => record?.certifier?.id === value,
    },
  ];

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onFinish = async (values: any) => {
    if (values.dates) {
      setTo(moment(values.dates[1]).unix());
      setFrom(moment(values.dates[0]).unix());
    } else {
      setTo(Number());
      setFrom(Number());
    }

    setSearching(true);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const renderIterations = (data: any) => {
    const inter = new Map(Object.entries(INTERACTIONS));
    const iterable = Object.keys(data).map((key) => ({
      value: data[key],
      key: key,
    }));
    return <Descriptions
        column={1}
        title="Interacciones"
        style={{
          marginTop: '20px'
        }}
        bordered>
        {iterable.map((obj:any) => (<Descriptions.Item label={inter.get(obj.key)}>{obj.value}</Descriptions.Item>))}
      </Descriptions>
  };

  const removeById = async(id: string) => {
    await LotService.removeById(id);
    setLots(lots?.filter((lot: Lot) => lot.id !== id));
    setDataLot(undefined);
    setRowSelected('');
    setVisible(false);
  };

  return (
    <LayoutGeneral>
      <Content style={{ margin: '20px 16px 0 16px' }}>
        {lots ? 
        <>
          <div id="form-row">
            <h1>Lotes</h1>
            <Form
              {...layout}
              name="basic"
              layout="inline"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Fecha"
                name="dates"
              >
                <RangePicker
                  disabledDate={(currentDate) => currentDate > moment()}
                />
              </Form.Item>
              <Button type="primary" htmlType="submit">
                Buscar
              </Button>
            </Form>
          </div>
          <>
            <p>Total lotes: {amountLots} Total Animales: {amountAnimals}</p>
          </>
        <Table
          columns={columns}
          dataSource={lots}
          pagination={{
            defaultPageSize: 100,
          }}
          loading={loadingData}
          scroll={{ x: 1300, y: currentHeightScreen }}
          rowClassName={(record, index) => {
            if (rowSelected === index) {
              return 'selected';
            }
            return '';
          }}
          onRow={(record: Lot, rowIndex: any) => {
            return {
              onClick: async(event: any) => {
                // call open drawer
                if (!visible) {
                  setVisible(true);
                }
                setDataLot(undefined);
                // call service to get data by id
                const res = await LotService.getById(record.id);
                setDataLot({
                  ...res.data,
                  refNumber: record.refNumber,
                });
                if (rowSelected === rowIndex) {
                  setRowSelected('');
                  setVisible(false);
                } else {
                  setRowSelected(rowIndex)
                }
              },
            };
          }}
          onChange={(pagination: any, filters: any, sorter: any, extra: {
            currentDataSource: Lot[],
          }) => {
            setFilterValue(filters);
            setSorterValue(sorter);

            setAmountLots(extra.currentDataSource? extra.currentDataSource.length : 0);
            setAmountAnimals(extra.currentDataSource ? extra.currentDataSource?.length > 0 ? extra.currentDataSource?.map(lot => lot?.animalsCount).reduce((total: any, value: any) => total + value) : 0 : 0);

            if (filters.categoryName) {
              setFilteredCategories(extra.currentDataSource.filter((lot: Lot) => filters.categoryName.includes(lot.category.name)));
            }

            if (filters.certifierName) {
              setFilteredCertifiers(extra.currentDataSource.filter((lot: Lot) => filters.certifierName.includes(lot.certifier?.name)));
            }

            // merge of filters
            setLotsAux(extra.currentDataSource);

            setAllFiltered(_.intersectionBy([extra.currentDataSource, filteredCategoriesRef?.current, filteredCertifiersRef?.current], 'id')[0]);

            if (Object.values(filters).every(o => o === null)) {
              setLotsAux(lots);
              setRestart(true);
            }
            setSearching(true);
          }}
        />
        <Modal title="Asignacion de comercial" visible={isAsignCertifierModalVisible} onOk={handleOk} onCancel={handleCancel}>
          <Select
            style={{ width: 200 }}
            defaultValue={selectCertifier?.id}
            placeholder="Selecíon de comercial"
            onChange={onChange}
          >
            {certifiers?.map((certifier: Certifier) => <Option value={certifier.id}>{certifier.name.first} {certifier.name.last}</Option>)}
          </Select>
        </Modal>
        <Drawer
          title={`Detalle Lote ${dataLot ? '#' + dataLot.refNumber : ''}`}
          placement="right"
          mask={false}
          onClose={() => {
            setVisible(false);
            setRowSelected('');
          }}
          visible={visible}
          width="30%"
        >
          {dataLot ?
          <>
            <div className="actions" style={{
              marginBottom: '20px',
            }}>
              <Popconfirm
                title="Eliminar?"
                onConfirm={() => removeById(dataLot.id)}
                okText="Si"
              >
                <Button danger>Eliminar</Button>
              </Popconfirm>
              {dataLot.state === 'ACTIVE' && 
                <Button
                  type="link"
                  href={dataLot.link}
                  target="blank"
                >Link web</Button>}
              {dataLot?.storyVideoUrl && 
                <Button
                  type="link"
                  href={dataLot.storyVideoUrl}
                  target="blank"
                >Historia</Button>}
              {dataLot?.squareAdVideoUrl && 
                <Button
                  type="link"
                  href={dataLot.squareAdVideoUrl}
                  target="blank"
                >Facebook Ad Video</Button>}
              {dataLot?.squareAdScreenshotUrl && 
                <Button
                  type="link"
                  href={dataLot.squareAdScreenshotUrl}
                  target="blank"
                >Facebook Ad Imagen</Button>}
              <Button
                type="link"
                onClick={showAsignCertifierModal}
              >Asignar</Button>
            </div>
            <Descriptions
              column={1}
              bordered
            >
              <Descriptions.Item label="Categoria">{dataLot.category}</Descriptions.Item>
              <Descriptions.Item label="Cant Animales">{dataLot.animalsCount}</Descriptions.Item>
              <Descriptions.Item label="Comercial">{dataLot.certifier}</Descriptions.Item>
              {dataLot.seller && <Descriptions.Item label="Vendedor">{dataLot.seller}</Descriptions.Item>}
            </Descriptions>
            <div className="video">
              <video src={dataLot.videoUrl} style={{
                width: '100%'
              }} controls />
            </div>
            {renderIterations(dataLot?.interactions)}
          </>
          : <Space direction="vertical" size={12}><Spin /></Space>}
        </Drawer>
        </>
        : <Space direction="vertical" size={12}><Spin /></Space>}
      </Content>
    </LayoutGeneral>
  );
}

export default Lots;