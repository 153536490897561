import { Layout } from 'antd';
import { useContext, useState } from 'react';
import Sidebar from '../SideBar';

import packageJson from '../../../package.json';
import { AuthContext } from '../../context/auth';

const GeneralLayout = ({
  children,
}: {
  children: React.ReactChild,
}) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const onCollapse = (collapsed: any) => {
    setCollapsed(collapsed);
  };

  const { user } = useContext<any>(AuthContext);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {user && (
        <Sidebar collapsed={collapsed} onCollapse={onCollapse} />
      )}
      <span style={{
        position: 'absolute',
        top: 0,
        right: 5,
        color: 'grey'
      }}>v.{packageJson.version}</span>
      <Layout className="site-layout">
        {children}
      </Layout>
    </Layout>
  );
}

export default GeneralLayout
