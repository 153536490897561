import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';
import reportWebVitals from './reportWebVitals';

import { Provider as AlertProvider } from 'react-alert';
// @ts-ignore
import AlertTemplate, { options as AlertTemplateOptions } from './components/Alert';
import { AuthProvider } from './context/auth';


ReactDOM.render(
  <React.StrictMode>
    <AlertProvider template={AlertTemplate} {...AlertTemplateOptions}>
      <AuthProvider>
        <GoogleOAuthProvider clientId="929971127969-pvvqqcfkho1khk9njhtdaaoqdc8nvucg.apps.googleusercontent.com">
          <App />
        </GoogleOAuthProvider>
      </AuthProvider>
    </AlertProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
