import { useState, useEffect, useRef } from 'react';
import * as UserService from '../../services/User';

import { Table, Input, Button, Space, Spin, Form, DatePicker } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

import { User } from '../../utils/general';

import { Layout } from 'antd';
import LayoutGeneral from '../../components/Layout';
import moment from 'moment';
import 'moment/locale/es';

import './styles.scss';

const { Content } = Layout;

const { RangePicker } = DatePicker;

const Users = () => {
  const [users, setUsers] = useState<User[]>();
  const [usersAux, setUsersAux] = useState<User[]>();
  const [to, setTo] = useState<number>();
  const [from, setFrom] = useState<number>();

  const [searchedColumn, setSearchedColumn] = useState<any>();
  const [searchText, setSearchText] = useState<any>();
  const searchInput = useRef<any>();

  const [rowSelected, setRowSelected] = useState<any>();

  const [amountUsers, setAmountUsers] = useState<number>();

  const [filterValue, setFilterValue] = useState<any>();
  const [sorterValue, setSorterValue] = useState<any>();

  const [searching, setSearching] = useState<boolean>();
  const [loadingData, setLoading] = useState<boolean>(true);

  const [currentHeightScreen, setCurrentHeightScreen] = useState<number>();

  const clearFiltersValues = () => {
    setFilterValue(null);
    setSorterValue(null);
  };

  const handleResize = () => setCurrentHeightScreen((window.innerHeight - 250));
  window.addEventListener('resize', handleResize);

  const getData = async () => {
    setLoading(true);
    let res;
    if (to && from) {
      res = await UserService.getAll(from.toString(), to.toString());
    } else {
      res = await UserService.getAll();
    }

    const usersData : User[] = res.data?.map((user: User) => {
      return {
        ...user,
        firstName: user.name?.first,
        lastName: user.name?.last,
        createdFormated: moment(user.createdAt).format('ddd D MMMM YYYY'),
      }
    });

    setUsers(usersData);
    setUsersAux(usersData);
    setAmountUsers(usersData? usersData.length : 0);
    setSearching(false);
    setLoading(false);
  };

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    clearFiltersValues();
    getData();
  }, [searching === true]);

  useEffect(() => {
    setAmountUsers(users? users.length : 0);
  }, [users]);

  const getColumnSearchProps = (dataIndex: any) : any => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: {
      setSelectedKeys: Function,
      selectedKeys: [string],
      confirm: Function,
      clearFilters: Function
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: string) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: string, record: any) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible: string) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text: string) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys: any, confirm: Function, dataIndex: number) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters: Function) => {
    clearFilters();
    clearFiltersValues();
    setUsersAux(usersAux);
    setSearchText('');
  };

  const columns = [
    {
      title: 'Fecha',
      dataIndex: 'createdFormated',
      width: 200,
      key: 'createdFormated',
      sortOrder: sorterValue?.columnKey === 'createdFormated' && sorterValue.order,
      sorter: (a: User, b: User) => moment(a.createdAt).diff(moment(b.createdAt)),
    },
    {
      ...getColumnSearchProps('firstName'),
      title: 'Nombre',
      dataIndex: 'firstName',
      width: 150,
      key: 'firstName',
    },
    {
      ...getColumnSearchProps('lastName'),
      title: 'Apellido',
      dataIndex: 'lastName',
      width: 150,
      key: 'lastName',
    },
    {
      title: 'Correo Electrónico',
      dataIndex: 'email',
      width: 320,
      key: 'email',
    },
    {
      title: 'Telefono',
      dataIndex: 'phoneNumber',
      width: 200,
      key: 'phoneNumber',
    },
    {
      title: 'Departamento',
      dataIndex: 'department',
      key: 'department',
    },
  ];

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onFinish = async (values: any) => {
    if (values.dates) {
      setTo(moment(values.dates[1]).unix());
      setFrom(moment(values.dates[0]).unix());
    } else {
      setTo(Number());
      setFrom(Number());
    }

    setSearching(true);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <LayoutGeneral>
      <Content style={{ margin: '20px 16px 0 16px' }}>
        {users ? 
        <>
          <div id="form-row">
            <h1>Users</h1>
            <Form
              {...layout}
              name="basic"
              layout="inline"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Fecha"
                name="dates"
              >
                <RangePicker
                  disabledDate={(currentDate) => currentDate > moment()}
                />
              </Form.Item>
              <Button type="primary" htmlType="submit">
                Buscar
              </Button>
            </Form>
          </div>
          <>
            <p>Total usuarios: {amountUsers}</p>
          </>
        <Table
          columns={columns}
          dataSource={users}
          pagination={{
            defaultPageSize: 100,
          }}
          loading={loadingData}
          scroll={{ x: 1300, y: currentHeightScreen }}
          rowClassName={(record, index) => {
            if (rowSelected === index) {
              return 'selected';
            }
            return '';
          }}
          onChange={(pagination: any, filters: any, sorter: any, extra: {
            currentDataSource: User[],
          }) => {
            setFilterValue(filters);
            setSorterValue(sorter);
            if (extra.currentDataSource.length > 0) {
              setAmountUsers(extra.currentDataSource? extra.currentDataSource.length : 0);
              setUsers(extra.currentDataSource);
            }
            if (Object.values(filters).every(o => o === null)) {
              setUsersAux(usersAux);
              setSearching(true);
            }
          }}
        />
        </>
        : <Space direction="vertical" size={12}><Spin /></Space>}
      </Content>
    </LayoutGeneral>
  );
}

export default Users;
