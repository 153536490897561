import Api from "./Api"

export const importCsv = async (file: any) => {
  const formData = new FormData();
  formData.append(
    'file',
    file,
    file.name
  );
  return (await Api.request('/hubspot/import', 'POST', {
    'Content-Type':'multipart/form-data',
  }, formData)).data;
}; 
