import { Layout } from 'antd';
import LayoutGeneral from '../../components/Layout';

const { Content } = Layout;

const Marketing = () => (
  <LayoutGeneral>
    <Content style={{ margin: '0 16px' }}>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        Marketing
      </div>
    </Content>
  </LayoutGeneral>
);

export default Marketing;
