import React, { useState, useRef, useEffect } from 'react';
import { useAlert } from 'react-alert';
import {
  Form,
  Input,
  Button,
  Checkbox,
  DatePicker,
  Upload,
  message,
  Avatar,
  Space,
  Spin,
} from 'antd';
import { UploadOutlined, UserOutlined } from '@ant-design/icons';
import { Department } from '../../utils/general';

import * as CertifierService from '../../services/Certifier';
import * as UploadService from '../../services/Upload';

import moment from 'moment';

type SizeType = Parameters<typeof Form>[0]['size'];

const FormComponent = ({
  data,
  loading,
  setLoading,
  reload,
}: {
  data: any,
  departments?: Department[],
  loading: boolean,
  setLoading: Function,
  reload: Function,
}) => {
  const [componentSize, setComponentSize] = useState<SizeType | 'default'>('default');
  const onFormLayoutChange = ({ size }: { size: SizeType }) => {
    setComponentSize(size);
  };

  const firstName = useRef<any>(data.name?.first);
  const lastName = useRef<any>(data.name?.last);
  const email = useRef<any>(data.emailProp);
  const phoneNumber = useRef<any>(data?.phoneNumber);
  const birthDate = useRef<any>(data?.birthDate);
  const entryDate = useRef<any>(data?.entryDate);

  const [image, setImage] = useState<string>(data?.imageUrl);
  const [formData, setFormData] = useState<any>(new FormData());
  const [uploadList, setUploadList] = useState<any>([]);

  const [departmentsAux, setDepartmentsAux] = useState<any>(data.departments);

  const alert = useAlert();

  const uploadProps = {
    onChange(info: any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    defaultFileList: uploadList,
    fileList: uploadList,
  };

  const customRequest = async (options: any) => {
    try {
      formData.append(
        'file',
        options.file,
        options.file.name,
      );
      const response = await UploadService.file(formData);
      setImage(response.data.url);
      options.onSuccess('ok');
    } catch (err) {
      alert.error(err.message);
      options.onError(err);
    }
  }

  const toggleDepartment = (id: string, checked: boolean) => {
    const aux = departmentsAux.find((dep: Department) => dep.id == id);

    setDepartmentsAux([
      {
        ...aux,
        checked,
      },
      ...departmentsAux.filter((dep: Department) => dep.id != id),
    ].sort((a: Department, b: Department) => a.name > b.name ? 1 : -1));
  }

  const onFinish = async (values: any) => {
    try {
      let body = {
        ...values,
        imageUrl: image,
        departments: departmentsAux.filter((dep: Department) => dep.checked == true).map((dep: Department) => ({ _id: dep.id })),
      };
      if (data?.id != 0) {
        body = {
          ...body,
          id: data.id,
        }
      }
      await CertifierService.update(body);
      reload();
    } catch (err) {
      alert.error(err.message);
    }
  }

  useEffect(() => {
    setLoading(true);

    form.setFieldsValue({
      firstName: data.name.first,
      lastName: data.name.last,
      email: data.email,
      phoneNumber: data.phoneNumber,
      birthDate: data?.birthDate ? moment(data?.birthDate) : undefined,
      entryDate: data?.entryDate ? moment(data?.entryDate) : undefined,
    });

    setDepartmentsAux(data.departments);

    setImage(data?.imageUrl);

    setFormData(new FormData());
    setUploadList([]);

    setLoading(false);
  }, [data]);

  const [form] = Form.useForm();

  return (
    <>
      {loading ? <Space direction="vertical" size={12}><Spin /></Space> :
      <Form
        labelCol={{ offset: 'none' }}
        wrapperCol={{ span: 12 }}
        layout="horizontal"
        onValuesChange={onFormLayoutChange}
        size={componentSize as SizeType}
        initialValues={{
          firstName: data?.name?.first,
          lastName: data?.name?.lastName,
          email: data?.name?.email,
          phoneNumber: data?.name?.phoneNumber,
          birthDate: data?.birthDate ? moment(data?.birthDate) : undefined,
          entryDate: data?.entryDate ? moment(data?.entryDate) : undefined,
        }}
        form={form}
        onFinish={onFinish}
      >
        <Form.Item label="Nombre" name="firstName" rules={[{ required: true }]} requiredMark>
          <Input
            ref={firstName}
          />
        </Form.Item>
        <Form.Item label="Apellido" name="lastName" rules={[{ required: true }]} requiredMark>
          <Input
            ref={lastName}
          />
        </Form.Item>
        <Form.Item label="Email" name="email" rules={[{ required: true }]} requiredMark>
          <Input
            ref={email}
          />
        </Form.Item>
        <Form.Item label="Numero de telefono celular" name="phoneNumber" rules={[{ required: true }]} requiredMark>
          <Input
            ref={phoneNumber}
          />
        </Form.Item>
        <Form.Item label="Departamentos">
          {departmentsAux?.map((departament: Department) => <Checkbox checked={departament.checked} onChange={(event: any) => toggleDepartment(departament.id, event.target.checked)}>{departament.name}</Checkbox>)}
        </Form.Item>
        <Form.Item label="Fecha de Ingreso" name="entryDate" rules={[{ required: true }]} requiredMark>
          <DatePicker ref={entryDate} />
        </Form.Item>
        <Form.Item label="Fecha de cumpleaños" name="birthDate">
          <DatePicker ref={birthDate} />
        </Form.Item>

        <Avatar size={64} icon={<UserOutlined />} src={image} />
        <Upload {...uploadProps} customRequest={customRequest}>
          <Form.Item label="Imagen de perfil">
            <Button icon={<UploadOutlined />}>Subir</Button>
          </Form.Item>
        </Upload>
        <Form.Item>
          <Button type="primary" htmlType="submit">Guardar</Button>
        </Form.Item>
      </Form>}
    </>
  )
};

export default FormComponent;
