import { useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

// pages
import Dashboard from './pages/Dashboard';
import Marketing from './pages/Marketing';
import Login from './pages/Login';
import Lots from "./pages/Lots";
import Certifiers from "./pages/Certifiers";
import Newsletter from './pages/Newsletter';
import Subscriptions from "./pages/Subscriptions";
import Users from './pages/Users';
import Interactions from './pages/Interactions';
import WantPublish from "./pages/WantPublish";

import CacheBuster from './CacheBusting';

import { AuthContext } from './context/auth';
import Hubspot from "./pages/Hubspot";

const App = () => {
  const { user } = useContext<any>(AuthContext);

  return (
    <CacheBuster>
    {({ loading, isLatestVersion, refreshCacheAndReload }: {
      loading: boolean,
      isLatestVersion: boolean,
      refreshCacheAndReload: Function,
    }) => {
      if (loading) return null;
      if (!loading && !isLatestVersion) {
        refreshCacheAndReload();
      }

      return (
        <Router>
          <Switch>
            {user && (
              <>
                <Route path="/home" exact>
                  <Dashboard />
                </Route>
                <Route path="/marketing" exact>
                  <Marketing />
                </Route>
                <Route path="/lots" exact>
                  <Lots />
                </Route>
                <Route path="/certifiers">
                  <Certifiers />
                </Route>
                <Route path="/subscriptions">
                  <Subscriptions />
                </Route>
                <Route path="/newsletter">
                  <Newsletter />
                </Route>
                <Route path="/users">
                  <Users />
                </Route>
                <Route path="/hubspot">
                  <Hubspot />
                </Route>
                <Route path="/interactions">
                  <Interactions />
                </Route>
                <Route path="/want-publish">
                  <WantPublish />
                </Route>
                <Redirect to="/home" />
              </>
            )}
            <Route path="/login" exact>
              <Login />
            </Route>
            <Redirect to="/login" />
          </Switch>
        </Router>
        );
      }}
    </CacheBuster>
  );
}

export default App;
