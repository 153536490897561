import { useState, useEffect } from 'react';
import * as NewsletterService from '../../services/Newsletter';

import { Table, Space, Spin } from 'antd';

import { Subscription } from '../../utils/general';

import { Layout } from 'antd';
import LayoutGeneral from '../../components/Layout';

import '../Lots/styles.scss';

const { Content } = Layout;

const Subscriptions = () => {
  const [subscriptions, setSubscriptions] = useState<Subscription[]>();

  const [currentHeightScreen, setCurrentHeightScreen] = useState<number>();

  const handleResize = () => setCurrentHeightScreen((window.innerHeight - 100));
  window.addEventListener('resize', handleResize);

  const getData = async () => {
    const res = await NewsletterService.getAll();

    setSubscriptions(res.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      width: 200,
      key: 'email',
    },
    {
      title: 'Telefono',
      dataIndex: 'phoneNumber',
      width: 200,
      key: 'phoneNumber',
    },
  ];

  return (
    <LayoutGeneral>
      <Content style={{ margin: '20px 16px 0 16px' }}>
        {subscriptions ?
        <Table
          columns={columns}
          dataSource={subscriptions}
          pagination={{
            defaultPageSize: 100,
          }}
          scroll={{ x: 1800, y: currentHeightScreen }}
        />
        : <Space direction="vertical" size={12}><Spin /></Space>}
      </Content>
    </LayoutGeneral>
  );
}

export default Subscriptions;
