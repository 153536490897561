import axios, {Method} from 'axios';
import Cookies from 'js-cookie';

class Api {
  base = "https://api.campomercado.com";
  baseConfig = {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${Cookies.get('token')}`,
      'x-api-key': process.env.REACT_APP_APIKEY,
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  };

  updateToken = () => this.baseConfig = {
    headers: {
      ...this.baseConfig.headers,
      Authorization: `Bearer ${Cookies.get('token')}`
    },
    mode: this.baseConfig.mode,
  };

  get(url: string) {
    return axios.get(this.base + url, this.baseConfig)
  }

  post = (url: string, data: any) => {
    return axios.post(this.base + url, data, {
      headers: {
        ...this.baseConfig.headers,
        Authorization: `Bearer ${Cookies.get('token')}`,
      }
    });
  };

  put = (url: string, data: any) => {
    return axios.put(this.base + url, data, {...this.baseConfig});
  };

  patch = (url: string, data: any) => {
    return axios.patch(this.base + url, data, {...this.baseConfig});
  };

  delete = (url: string) => {
    return axios.delete(this.base + url, {...this.baseConfig});
  };

  request = (url: string, method: Method, extraConfig: any, data: any) => {
    return axios.request({
      method,
      url: this.base + url,
      ...this.baseConfig,
      ...extraConfig,
      data,
    })
  }
}

axios.interceptors.request.use(
  undefined,
  (error) => {
    if (error.response.status === 401 || error.response.data.message === '401 Unauthorized') {
      window.location.href = '/';
    }
  }
);

axios.interceptors.response.use(
  undefined,
  (error) => {
    if (error.response?.status === 401) {
      throw Error(error.response.data.message);
    }
    return error;
  }
);

export default new Api();
