import { Alert } from 'antd';
import { transitions, positions } from 'react-alert';

const AlertTemplate = ({ options, message, close } : any) => (
  <Alert
    type={options.type}
    message={message}
    closable
    onClose={close}
  />
);

// optional configuration
export const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}

export default AlertTemplate;
