import { useContext } from 'react';
import { Layout, Menu, Divider, Avatar } from 'antd';
import {
  DesktopOutlined,
  FundOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import {
  Link,
} from "react-router-dom";
import { CollapseType } from 'antd/lib/layout/Sider';
import Cookies from 'js-cookie';
import { AuthContext } from '../../context/auth';

const { Sider } = Layout;

const Sidebar = ({
  collapsed,
  onCollapse,
}: {
  collapsed: boolean,
  onCollapse: (collapsed: boolean, type: CollapseType) => void,
}) => {
  const { user, setUser } = useContext<any>(AuthContext);

  const handleLogout = () => {
    setUser(null);
    Cookies.remove('token');
    Cookies.remove('user');
  };

  return (
  <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
    <div className="logo" />
    <Menu theme="dark" mode="inline">
      <Menu.Item key="0">
        <Link to="/home">
          <div>
            <Avatar size="large" src={user?.imageUrl} />
            <span>{user?.name?.first} {user?.name?.last}</span>
          </div>
        </Link>
      </Menu.Item>
      <Menu.Item key="10" icon={<LogoutOutlined />} onClick={handleLogout}>
        Logout
      </Menu.Item>
      <Divider type="vertical" />
      <Menu.Item key="1" icon={<DesktopOutlined />}>
        <Link to="/lots">Lotes</Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<DesktopOutlined />}>
        <Link to="/certifiers">Comerciales</Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<DesktopOutlined />}>
        <Link to="/subscriptions">Subscriptos</Link>
      </Menu.Item>
      <Menu.Item key="4" icon={<DesktopOutlined />}>
        <Link to="/newsletter">Newsletter</Link>
      </Menu.Item>
      <Menu.Item key="6" icon={<DesktopOutlined />}>
        <Link to="/users">Usuarios</Link>
      </Menu.Item>
      <Menu.Item key="7" icon={<DesktopOutlined />}>
        <Link to="/hubspot">HubSpot</Link>
      </Menu.Item>
      <Menu.Item key="8" icon={<DesktopOutlined />}>
        <Link to="/interactions">Interacciones</Link>
      </Menu.Item>
      <Menu.Item key="9" icon={<DesktopOutlined />}>
        <Link to="/want-publish">Quieren Publicar</Link>
      </Menu.Item>
    </Menu>
  </Sider>
  );
}

export default Sidebar;
