import { useState, useEffect, useRef } from 'react';
import * as UserService from '../../services/User';

import { Table, Input, Button, Space, Spin, Form } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

import { UserWantPublish } from '../../utils/general';

import { Layout } from 'antd';
import LayoutGeneral from '../../components/Layout';
import moment from 'moment';
import 'moment/locale/es';

import './styles.scss';

const { Content } = Layout;

const WantPublish = () => {
  const [users, setUsers] = useState<UserWantPublish[]>();
  const [loadingData, setLoading] = useState<boolean>(true);

  const [searchedColumn, setSearchedColumn] = useState<any>();
  const [searchText, setSearchText] = useState<any>();
  const searchInput = useRef<any>();
  const [filterValue, setFilterValue] = useState<any>();
  const [sorterValue, setSorterValue] = useState<any>();
  const [rowSelected, setRowSelected] = useState<any>();

  const [currentHeightScreen, setCurrentHeightScreen] = useState<number>();

  const handleResize = () => setCurrentHeightScreen((window.innerHeight - 250));
  window.addEventListener('resize', handleResize);

  const getData = async () => {
    setLoading(true);
    const res = await UserService.getWantPublish();

    const usersData : UserWantPublish[] = res.data?.map((wantPublish: UserWantPublish) => {
      return {
        ...wantPublish,
        firstName: wantPublish?.firstName,
        lastName: wantPublish?.lastName,
        phoneNumber: wantPublish?.phoneNumber,
        dateFormated: moment(wantPublish.createdAt).format('ddd D MMMM YYYY'),
      }
    });

    setUsers(usersData);
    setLoading(false);
  };

  useEffect(() => {
    getData();
    handleResize();
  }, []);

  const clearFiltersValues = () => {
    setFilterValue(null);
    setSorterValue(null);
  };

  const handleSearch = (selectedKeys: any, confirm: Function, dataIndex: number) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters: Function) => {
    clearFilters();
    clearFiltersValues();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: any) : any => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: {
      setSelectedKeys: Function,
      selectedKeys: [string],
      confirm: Function,
      clearFilters: Function
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: string) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: string, record: any) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible: string) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text: string) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'Fecha',
      dataIndex: 'dateFormated',
      width: 35,
      key: 'dateFormated',
      sorter: (a: UserWantPublish, b: UserWantPublish) => moment(a.createdAt).diff(moment(b.createdAt)),
    },
    {
      ...getColumnSearchProps('firstName'),
      title: 'Nombre',
      dataIndex: 'firstName',
      width: 35,
      key: 'firstName',
    },
    {
      ...getColumnSearchProps('lastName'),
      title: 'Apellido',
      dataIndex: 'lastName',
      width: 35,
      key: 'lastName',
    },
    {
      ...getColumnSearchProps('phoneNumber'),
      title: 'Telefono',
      dataIndex: 'phoneNumber',
      width: 35,
      key: 'phoneNumber',
    },
  ];

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <LayoutGeneral>
      <Content style={{ margin: '20px 16px 0 16px' }}>
        {users ? 
        <>
          <div id="form-row">
            <h1>Quieren publicar</h1>
          </div>
          <Table
          columns={columns}
          dataSource={users}
          pagination={{
              defaultPageSize: 100,
          }}
          loading={loadingData}
          scroll={{ x: 1300, y: currentHeightScreen }}
          rowClassName={(record, index) => {
              if (rowSelected === index) {
              return 'selected';
              }
              return '';
          }}
          onChange={(pagination: any, filters: any, sorter: any, extra: {
              currentDataSource: UserWantPublish[],
          }) => {
              setFilterValue(filters);
              setSorterValue(sorter);
              if (extra.currentDataSource.length > 0) {
                setUsers(extra.currentDataSource);
              }
          }}
          />
        </>
        : <Space direction="vertical" size={12}><Spin /></Space>}
      </Content>
    </LayoutGeneral>
  );
}

export default WantPublish;
