import Cookies from "js-cookie";
import { createContext, useState } from "react";

interface AuthUser {
  email?: string,
  imageUrl?: string,
  name?: {
    first?: string,
    last?: string,
  }
  phoneNumber?: string,
  roles?: string[],
  token?: string,
};

interface AuthContextType {
  user: AuthUser,
  setUser: Function,
}

export const AuthContext = createContext<AuthContextType>({
  user: {
    email: "",
    imageUrl: "",
    name: {},
    phoneNumber: "",
    roles: [],
    token: "",
  },
  setUser: () => {},
});

export const AuthProvider = ({ children } : any) => {
  const [user, setUser] = useState<AuthUser>(Cookies.get('token') && Cookies.get('user') ? Cookies.getJSON('user') : null);

  return <AuthContext.Provider value={{
    user,
    setUser,
  }}>{children}</AuthContext.Provider>
};
