import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { useAlert } from 'react-alert';
import { Layout, Row, Col } from 'antd';
import LayoutGeneral from '../../components/Layout';
import * as AuthService from '../../services/Auth';
import Cookies from 'js-cookie';
import Api from '../../services/Api';
import { AuthContext } from '../../context/auth';

const { Content } = Layout;

const Login = () => {
  const history = useHistory();
  const alert = useAlert();

  const { setUser } = useContext<any>(AuthContext);

  const onSuccess = async (response: any) => {
    console.log(response);
    const {
      credential,
    } = response;

    try {
      const res = await AuthService.loginWithGoogle({
        'idToken': credential,
      });
      setUser(res.data);
      Cookies.set('user', JSON.stringify(res.data));
      Cookies.set('token', res.data.token);
      Api.updateToken();
      history.push('/home')
    } catch (err) {
      alert.error(err.message);
    }
  }

  const responseGoogle = (response: any) => {
    try {
      return response;
    } catch (err) {
      alert.error(err.message);
    }
  }

  return (
    <LayoutGeneral>
      <Content style={{ marginTop: '20px' }}>
        <Row>
          <Col span="8" offset="8">
            <img src={`.//assets/img/logo.svg`} alt="logo-campomercado" />
          </Col>
        </Row>
        <Row justify="center">
          <Col>
            <p className="height-200">
              <GoogleLogin
                onSuccess={credentialResponse => {
                  onSuccess(credentialResponse);
                }}
                onError={() => {
                  console.log('Login Failed');
                }}
              />
            </p>
          </Col>
        </Row>
      </Content>
    </LayoutGeneral>
  );
}

export default Login;
