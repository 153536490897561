import Api from './Api';

export const getAll = (from?: number, to?: number) => Api.get(`/dashboard/lot${from ? `?from=${from}&to=${to}` : ''}`);

export const getById = (id: string) => Api.get(`/dashboard/lot/${id}`);

export const removeById = (id: string) => Api.delete(`/dashboard/lot/${id}`);

export const getDepartaments = () => Api.get(`/dashboard/departments`);

export const updateCertifier = (lotId: string, certifierId: string) => Api.put(`/dashboard/lot/${lotId}/certifier`, {
  certifierId,
});
