export type UserType = {
  _id: string,
  name: {
    first: string,
    last: string,
  },
  phoneNumber: string,
  email: string,
  roles: string,
  token: string,
  imageUrl: string
};

export type UserWantPublish = {
  _id: string,
  lastName: string,
  firstName: string,
  phoneNumber: string,
  createdAt: Date,
};

export enum SOLD_STATE {
  SOLD = 'SOLD',
  NOT_SOLD = 'NOT_SOLD',
  SELLING = 'SELLING',
}

export enum LOT_STATE {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface Lot {
  id: string,
  animalsCount?: number,
  refNumber: string,
  createdAt: Date,
  inactiveSince?: Date,
  state: LOT_STATE,
  activeDays: number,
  sold: SOLD_STATE,
  interactions: number,
  category: LotCategory,
  certifier?: Certifier,
};

export interface LotInteraction {
  id: string,
  firstName: string,
  lastName: string,
  phoneNumber: string,
  email: string,
  type: InteractionType,
  createdAt: Date,
  lotNumber: number,
  quality: Quality,
  hubspot: string,
  existedInHubSpot: boolean,
};

export enum InteractionType {
  CONSULTATION = 'CONSULTATION',
  OFFER = 'OFFER',
  CALL = 'CALL',
  WHATSAPP = 'WHATSAPP',
  SEE_PHONE = 'SEE_PHONE',
  WANT_TO_BE_CALLED = 'WANT_TO_BE_CALLED',
};

export enum Quality {
  GOOD = 'GOOD',
};

export interface User {
  _id: string,
  createdAt: Date,
  name: {
    first: string,
    last: string,
  },
  email: string,
  phoneNumber: string,
  departments: [Department],
  firstName?: string,
  lastName?: string,
  createdFormated: string,
};

export interface Subscription {
  id: string,
  email: string,
  phoneNumber: string,
};

export interface LotCategory {
  name: string,
  id: string,
  _id?: string,
  total?: number,
};

export interface Certifier {
  name: {
    first: string,
    last: string,
  },
  id: string,
  total?: number,
};

export interface CertifierDetail {
  name: {
    first: string,
    last: string,
  },
  email: string,
  id: string,
  phoneNumber: string,
  imageUrl: string | undefined,
  departments: Department[] | undefined,
  departamentsValues: string | undefined,
  birthDate: Date | undefined,
  entryDate: Date | undefined,
};

export interface Interaction {
  offerCount: number,
  consultationCount:number,
  wanttobecalledCount: number,
  whatsAppCount: number,
  callCount: number
}

export interface Department {
  capital: {
    name: string,
  },
  name: string,
  id: string,
  checked?: boolean,
};

export interface LotDetail {
  link: string,
  refNumber: string,
  id: string,
  videoUrl: string,
  animalsCount?: number,
  interactions: Interaction,
  category: string,
  certifier?: string,
  seller?: string,
  createdAt: Date,
  department: string,
  inactiveSince?: Date,
  sold: SOLD_STATE
  state: LOT_STATE,
  storyVideoUrl?: string,
  squareAdVideoUrl?: string,
  squareAdScreenshotUrl?: string, 
};

export const INTERACTIONS = {
  offerCount : 'Ofertas',
  consultationCount : 'Consultas',
  wanttobecalledCount : 'Quieren que lo llamen',
  whatsAppCount : 'WhatsApp',
  callCount : 'Telefono',
}
