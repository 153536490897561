import { Layout, Upload } from 'antd';
import LayoutGeneral from '../../components/Layout';
import { Button } from 'antd';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { useState } from 'react';

import moment from 'moment';
import download from 'downloadjs';

import * as HubspotService from '../../services/Hubspot';

const { Content } = Layout;

const Hubspot = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [uploaded, setUploaded] = useState<boolean>(false);
  const [newCsv, setNewCsv] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");

  const reset = () => {
    setFileName("");
    setNewCsv("");
    setUploaded(false);
  };

  const downloadAgain = () => {
    download(newCsv, fileName, "text/plain");
  }

  return (
    <LayoutGeneral>
      <Content style={{ margin: '0 16px' }}>
        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            Hubspot
        </div>
        <div>
        {newCsv === "" && <Upload
          customRequest={async ({ file }) => {
          try {
            setLoading(true);
            const newFile = await HubspotService.importCsv(file);
            setNewCsv(newFile);
            setUploaded(true);
            const name = `${moment().unix()}.csv`;
            setFileName(name);
            setLoading(false);
            download(newFile, name, "text/plain");
          } catch (err) {
            setLoading(false);
          }
        }}>
          <Button icon={<UploadOutlined />} disabled={loading}>CSV de contactos</Button>
        </Upload>}
        {uploaded && newCsv &&
          <>
            <Button icon={<UploadOutlined />} onClick={reset}>Volver a subir</Button>
            <Button icon={<DownloadOutlined />} onClick={downloadAgain} >Volver a Descargar</Button>
          </>
        }
        </div>
      </Content>
    </LayoutGeneral>
  );
}

export default Hubspot;
