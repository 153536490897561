import { Layout } from 'antd';
import LayoutGeneral from '../../components/Layout';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useState } from 'react';

import moment from 'moment';
import download from 'downloadjs';

import * as NewsletterService from '../../services/Newsletter';

const { Content } = Layout;

const Newsletter = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const downloadContacts = async () => {
    setLoading(true);
    const req = await NewsletterService.getContacts();
    download(req.data, `${moment().unix()}.csv`, "text/plain");
    setLoading(false);
  };

  return (
    <LayoutGeneral>
      <Content style={{ margin: '0 16px' }}>
        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
          Newsletter
        </div>
        <div>
        <Button type="primary" icon={<DownloadOutlined />} loading={loading} size='large' onClick={downloadContacts}>
          Contactos CSV
        </Button>
        </div>
      </Content>
    </LayoutGeneral>
  );
}

export default Newsletter;
