import { useState, useEffect, useRef } from 'react';
import * as InteractionService from '../../services/Interaction';

import { Table, Input, Button, Space, Spin, Form, DatePicker, Descriptions } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

import { INTERACTIONS, InteractionType, LotInteraction } from '../../utils/general';

import { Layout } from 'antd';
import LayoutGeneral from '../../components/Layout';
import moment from 'moment';
import 'moment/locale/es';

import './styles.scss';

const { Content } = Layout;

const { RangePicker } = DatePicker;

const Lots = () => {
  const [interactions, setInteractions] = useState<LotInteraction[]>();
  const [interactionsAux, setInteractionsAux] = useState<LotInteraction[]>();
  const [to, setTo] = useState<number>();
  const [from, setFrom] = useState<number>();

  const [searchedColumn, setSearchedColumn] = useState<any>();
  const [searchText, setSearchText] = useState<any>();
  const searchInput = useRef<any>();

  const [rowSelected, setRowSelected] = useState<any>();

  const [amountOffer, setAmountOffer] = useState<number>();
  const [amountConsultation, setAmountConsultation] = useState<number>();
  const [amountWhatsapp, setAmountWhatsapp] = useState<number>();
  const [amountCall, setAmountCall] = useState<number>();
  const [amountSeePhone, setAmountSeePhone] = useState<number>();
  const [amountWantToBeCalled, setAmountWantToBeCalled] = useState<number>();
  const [totalAmount, setTotalAmount] = useState<number>();

  const [filterValue, setFilterValue] = useState<any>();
  const [sorterValue, setSorterValue] = useState<any>();

  const [searching, setSearching] = useState<boolean>();
  const [loadingData, setLoading] = useState<boolean>(true);

  const [currentHeightScreen, setCurrentHeightScreen] = useState<number>();

  const clearFiltersValues = () => {
    setFilterValue(null);
    setSorterValue(null);
  };

  const handleResize = () => setCurrentHeightScreen((window.innerHeight - 330));
  window.addEventListener('resize', handleResize);

  const getData = async () => {
    setLoading(true);
    let res;
    if (to && from) {
      res = await InteractionService.getAll(from, to);
    } else {
      res = await InteractionService.getAll();
    }

    const interactionsData : LotInteraction[] = res.data.map((interaction: LotInteraction) => {
      return {
        ...interaction,
        createdAtFormated: moment(interaction.createdAt).format('ddd D MMMM YYYY'),
        hubspot: interaction.existedInHubSpot ? 'YES' : 'NO',
      }
    });

    setTotalAmount(interactionsData.length);
    setAmountOffer(interactionsData.filter((interaction: LotInteraction) => interaction.type === InteractionType.OFFER).length);
    setAmountConsultation(interactionsData.filter((interaction: LotInteraction) => interaction.type === InteractionType.CONSULTATION).length);
    setAmountWhatsapp(interactionsData.filter((interaction: LotInteraction) => interaction.type === InteractionType.WHATSAPP).length)
    setAmountWantToBeCalled(interactionsData.filter((interaction: LotInteraction) => interaction.type === InteractionType.WANT_TO_BE_CALLED).length)
    setAmountCall(interactionsData.filter((interaction: LotInteraction) => interaction.type === InteractionType.CALL).length)
    setAmountSeePhone(interactionsData.filter((interaction: LotInteraction) => interaction.type === InteractionType.SEE_PHONE).length)

    setInteractions(interactionsData);
    setInteractionsAux(interactionsData);
    setSearching(false);
    setLoading(false);
  };

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    clearFiltersValues();
    getData();
  }, [searching === true]);

  const getColumnSearchProps = (dataIndex: any) : any => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: {
      setSelectedKeys: Function,
      selectedKeys: [string],
      confirm: Function,
      clearFilters: Function
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: string) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: string, record: any) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible: string) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text: string) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys: any, confirm: Function, dataIndex: number) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters: Function) => {
    clearFilters();
    clearFiltersValues();
    setInteractions(interactionsAux);
    setSearchText('');
  };

  const columns = [
    {
      title: 'Fecha',
      dataIndex: 'createdAtFormated',
      key: 'createdAtFormated',
      sortOrder: sorterValue?.columnKey === 'createdAtFormated' && sorterValue.order,
      sorter: (a: LotInteraction, b: LotInteraction) => moment(a.createdAt).diff(moment(b.createdAt)),
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      width: 100,
      key: 'type',
      filters: [
        {
          text: 'CONSULTATION',
          value: 'CONSULTATION',
        },
        {
          text: 'OFFER',
          value: 'OFFER',
        },
        {
          text: 'CALL',
          value: 'CALL',
        },
        {
          text: 'WHATSAPP',
          value: 'WHATSAPP',
        },
        {
          text: 'SEE_PHONE',
          value: 'SEE_PHONE',
        },
        {
          text: 'WANT_TO_BE_CALLED',
          value: 'WANT_TO_BE_CALLED',
        },
      ],
      onFilter: (value: string, record: LotInteraction) => record.type === value,
    },
    {
      title: 'Nombre',
      dataIndex: 'firstName',
      key: 'firstName',
      onFilter: (value: string, record: LotInteraction) => record.firstName === value,
    },
    {
      title: 'Apellido',
      dataIndex: 'lastName',
      key: 'lastName',
      onFilter: (value: string, record: LotInteraction) => record.lastName === value,
    },
    {
      title: 'Tel.',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      onFilter: (value: string, record: LotInteraction) => record.phoneNumber === value,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      onFilter: (value: string, record: LotInteraction) => record.email === value,
    },
    {
      title: 'Calidad',
      dataIndex: 'quality',
      width: 100,
      key: 'quality',
      filters: [
        {
          value: 'GOOD',
          text: 'GOOD',
        },{
          value: 'BAD',
          text: 'BAD',
        },{
          value: 'UNKOWN',
          text: 'UNKOWN',
        }
      ],
      onFilter: (value: string, record: LotInteraction) => record.quality === value,
    },
    {
      title: 'En HubSpot',
      dataIndex: 'hubspot',
      width: 100,
      key: 'hubspot',
      filters: [
        {
          value: 'YES',
          text: 'YES',
        },
        {
          value: 'NO',
          text: 'NO',
        }
      ],
      onFilter: (value: string, record: LotInteraction) => record.hubspot === value,
    },
    {
      title: '#',
      dataIndex: 'lotNumber',
      width: 100,
      key: 'lotNumber',
      ...getColumnSearchProps('lotNumber'),
      sortOrder: sorterValue?.columnKey === 'lotNumber' && sorterValue.order,
      sorter: (a: LotInteraction, b: LotInteraction) => Number(a.lotNumber) - Number(b.lotNumber),
    },
  ];

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onFinish = async (values: any) => {
    if (values.dates) {
      setTo(moment(values.dates[1]).unix());
      setFrom(moment(values.dates[0]).unix());
    } else {
      setTo(Number());
      setFrom(Number());
    }

    setSearching(true);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const renderIterations = (data: any) => {
    const inter = new Map(Object.entries(INTERACTIONS));
    const iterable = Object.keys(data).map((key) => ({
      value: data[key],
      key: key,
    }));
    return <Descriptions
        column={1}
        title="Interacciones"
        style={{
          marginTop: '20px'
        }}
        bordered>
        {iterable.map((obj:any) => (<Descriptions.Item label={inter.get(obj.key)}>{obj.value}</Descriptions.Item>))}
      </Descriptions>
  };

  return (
    <LayoutGeneral>
      <Content style={{ margin: '20px 16px 0 16px' }}>
        {interactions ? 
        <>
          <div id="form-row">
            <h1>Interacciones</h1>
            <Form
              {...layout}
              name="basic"
              layout="inline"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Fecha"
                name="dates"
              >
                <RangePicker
                  disabledDate={(currentDate) => currentDate > moment()}
                />
              </Form.Item>
              <Button type="primary" htmlType="submit">
                Buscar
              </Button>
            </Form>
          </div>
          <>
            <p>Ofertas: {amountOffer} Consultas: {amountConsultation} Whatsapp: {amountWhatsapp}</p>
            <p>Llamadas: {amountCall} Ver telefono: {amountSeePhone} Quiere que lo llamen: {amountWantToBeCalled}</p>
            <p>Todos: {totalAmount}</p>
          </>
        <Table
          columns={columns}
          dataSource={interactions}
          pagination={{
            defaultPageSize: 100,
          }}
          loading={loadingData}
          scroll={{ x: 1300, y: currentHeightScreen }}
          rowClassName={(record, index) => {
            if (rowSelected === index) {
              return 'selected';
            }
            return '';
          }}
          onChange={(pagination: any, filters: any, sorter: any, extra: {
            currentDataSource: LotInteraction[],
          }) => {
            const interactionsData = extra.currentDataSource;
            setTotalAmount(interactionsData.length);
            setAmountOffer(interactionsData.filter((interaction: LotInteraction) => interaction.type === InteractionType.OFFER).length);
            setAmountConsultation(interactionsData.filter((interaction: LotInteraction) => interaction.type === InteractionType.CONSULTATION).length);
            setAmountWhatsapp(interactionsData.filter((interaction: LotInteraction) => interaction.type === InteractionType.WHATSAPP).length)
            setAmountWantToBeCalled(interactionsData.filter((interaction: LotInteraction) => interaction.type === InteractionType.WANT_TO_BE_CALLED).length)
            setAmountCall(interactionsData.filter((interaction: LotInteraction) => interaction.type === InteractionType.CALL).length)
            setAmountSeePhone(interactionsData.filter((interaction: LotInteraction) => interaction.type === InteractionType.SEE_PHONE).length)
          }}
        />
        </>
        : <Space direction="vertical" size={12}><Spin /></Space>}
      </Content>
    </LayoutGeneral>
  );
}

export default Lots;