import { useState, useEffect, useRef } from 'react';

import * as LotService from '../../services/Lot';
import * as CertifierService from '../../services/Certifier';

import { Table, Input, Button, Space, Drawer, Spin, Popconfirm } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

import { CertifierDetail, Department } from '../../utils/general';

import { Layout } from 'antd';
import LayoutGeneral from '../../components/Layout';

import Form from './form';

import '../Lots/styles.scss';

const { Content } = Layout;

const Certifiers = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [certifiers, setCertifiers] = useState<CertifierDetail[]>();
  const [departaments, setDepartaments] = useState<Department[]>();

  const [newCertifier, setNewCertifier] = useState<boolean>();
  const [editCertifier, setEditCertifier] = useState<boolean>();

  const [dataCertifier, setDataCertifier ] = useState<CertifierDetail>();

  const [searchedColumn, setSearchedColumn] = useState<any>();
  const [searchText, setSearchText] = useState<any>();
  const searchInput = useRef<any>();

  const [rowSelected, setRowSelected] = useState<any>();

  const [visible, setVisible] = useState<boolean>(false);

  const [filterValue, setFilterValue] = useState<any>();
  const [sorterValue, setSorterValue] = useState<any>();

  const [currentHeightScreen, setCurrentHeightScreen] = useState<number>();

  const handleResize = () => setCurrentHeightScreen((window.innerHeight - 210));
  window.addEventListener('resize', handleResize);

  const getData = async () => {
    let res;
    res = await CertifierService.getAll();

    const resCertifiers = res.data.map((cer: CertifierDetail) => {
      const departamentsCurrent = cer?.departments?.map((dep: Department, index: number) => (
        index+1 < (cer.departments != null && cer.departments.length) ? `${dep.name}, ` : dep.name
      ));

      return {
        ...cer,
        firstName: cer.name.first,
        lastName: cer.name.last,
        departamentsValues: departamentsCurrent,
      };
    });

    setCertifiers(resCertifiers);
  };

  const getDepartments = async () => {
    const response = await LotService.getDepartaments();
    setDepartaments(response.data);
  }

  useEffect(() => {
    handleResize();
    getData();
    getDepartments();
  }, []);

  const reload = () => {
    handleResize();
    getData();
    getDepartments();
    setVisible(false);
    setRowSelected('');
  }

  const getColumnSearchProps = (dataIndex: any) : any => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: {
      setSelectedKeys: Function,
      selectedKeys: [string],
      confirm: Function,
      clearFilters: Function
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: string) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: string, record: any) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible: string) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text: string) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys: any, confirm: Function, dataIndex: number) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters: Function) => {
    clearFilters();
    setSearchText('')
  };

  const columns = [
    {
      title: 'nombre',
      dataIndex: 'firstName',
      key: 'firstName',
      ...getColumnSearchProps('firstName'),
      sortOrder: sorterValue?.columnKey === 'firstName' && sorterValue.order,
      sorter: (a: CertifierDetail, b: CertifierDetail) => a.name.first > b.name.first,
    },
    {
      title: 'apellido',
      dataIndex: 'lastName',
      key: 'lastName',
      ...getColumnSearchProps('lastName'),
      sortOrder: sorterValue?.columnKey === 'lastName' && sorterValue.order,
      sorter: (a: CertifierDetail, b: CertifierDetail) => a.name.last > b.name.last,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 400,
      key: 'email',
      sortOrder: sorterValue?.columnKey === 'email' && sorterValue.order,
      sorter: (a: CertifierDetail, b: CertifierDetail) => a?.email > b?.email,
    },
    {
      title: 'telefono',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Departamentos',
      dataIndex: 'departamentsValues',
      key: 'departamentsValues',
      filters: departaments?.map((department: Department) => ({
        text: department.name,
        value: department.name,
      })),
      filteredValue: filterValue?.departamentsValues || null,
      onFilter: (value: string, record: CertifierDetail) => {
        if (record?.departments?.map((dep: Department) => dep.name).includes(value)) {
          return record;
        }
      },
    },
  ];

  const removeById = async(id: string) => {
    await CertifierService.removeById(id);
    setCertifiers(certifiers?.filter((cer: CertifierDetail) => cer.id !== id));
    setDataCertifier(undefined);
    setRowSelected('');
    setVisible(false);
  };

  const createNewCertifier = () => {
    setLoading(true);
    setNewCertifier(true);
    setEditCertifier(false);
    setDataCertifier({
      name: {
        first: '',
        last: '',
      },
      id: '0',
      email: '',
      phoneNumber: '',
      departamentsValues: undefined,
      imageUrl: undefined,
      birthDate: undefined,
      entryDate: undefined,
      departments: departaments?.map((dep: Department) => ({
        ...dep,
        checked: false,
      }))
    })
    setVisible(true);
    setLoading(false);
  };

  return (
    <LayoutGeneral>
      <Content style={{ margin: '20px 16px 0 16px' }}>
        {certifiers ? 
        <>
          <h1>Comerciales</h1>
          <div><Button onClick={createNewCertifier}>Nuevo</Button></div>
          <Table
            id="certifiers"
            columns={columns}
            dataSource={certifiers}
            pagination={{
              defaultPageSize: 100,
            }}
            scroll={{ x: 1200, y: currentHeightScreen }}
            rowClassName={(record, index) => {
              if (rowSelected === index) {
                return 'selected';
              }
              return '';
            }}
            onRow={(record: CertifierDetail, rowIndex: any) => {
              return {
                onClick: (event: any) => {
                  setLoading(true);
                  // call open drawer
                  if (!visible) {
                    setVisible(true);
                  }
                  setNewCertifier(false);
                  setEditCertifier(true);

                  if (departaments) {
                    setDataCertifier({
                      ...record,
                      departments: departaments?.map((dep: Department) => ({
                        ...dep,
                        checked: record?.departments?.find((de: Department) => de.id === dep.id) ? true : false,
                      }))
                    });
                  } else {
                    setDataCertifier(record);
                  }

                  if (rowSelected === rowIndex) {
                    setRowSelected('');
                    setVisible(false);
                  } else {
                    setRowSelected(rowIndex)
                  }
                  setLoading(false);
                },
              };
            }}
            onChange={(pagination: any, filters: any, sorter: any, extra: {
              currentDataSource: CertifierDetail[],
            }) => {
              setFilterValue(filters);
              setSorterValue(sorter);
            }}
          />
          {(newCertifier || editCertifier) &&
          <Drawer
            title={'Comercial'}
            placement="right"
            mask={false}
            onClose={() => {
              setVisible(false);
              setRowSelected('');
            }}
            visible={visible}
            width="30%"
          >
            <>
              <div className="actions" style={{
                marginBottom: '20px',
              }}>
                {editCertifier && dataCertifier && <Popconfirm
                  title="Eliminar?"
                  onConfirm={() => removeById(dataCertifier?.id)}
                  okText="Si"
                >
                  <Button danger>Eliminar</Button>
                </Popconfirm>}
              </div>
              {newCertifier && !editCertifier ? 
                <Form data={dataCertifier} loading={loading} setLoading={setLoading} reload={reload} />
              : 
              <Form data={dataCertifier} loading={loading} setLoading={setLoading} reload={reload} />}
            </>
          </Drawer>}
        </>
        : <Space direction="vertical" size={12}><Spin /></Space>}
      </Content>
    </LayoutGeneral>
  );
}

export default Certifiers;
